import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedDatePipe } from 'common';

@Injectable()
export class HijiDateParserFormatter extends NgbDateParserFormatter {
  private locale = inject(LOCALE_ID);
  private localizedDatePipe = inject(LocalizedDatePipe);

  parse(value: string): NgbDateStruct | null {
    if (value != null) {
      const format = getLocaleDateFormat(this.locale.toString(), FormatWidth.Short);
      return this.parseCustomDateFormat(format, value);
    }
    return null;
  }

  parseCustomDateFormat(format: string, dateString: string) {
    const parts = format.match(/[a-zA-Z]+/g);
    const dateParts = dateString.match(/\d+/g);
    if (dateParts === null) return null;
    let year, month, day;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i].toLowerCase();
      if (part.includes('y')) {
        year = this.toInteger(dateParts[i]);
      } else if (part.includes('m')) {
        month = this.toInteger(dateParts[i]);
      } else if (part.includes('d')) {
        day = this.toInteger(dateParts[i]);
      }
    }

    //console.log(this.locale.toString(), format, dateString, parts, dateParts, { year, month, day });

    if (!year || !month || !day) return null;
    return { year, month, day };
  }

  toInteger(value) {
    return parseInt(`${value}`, 10);
  }
  isNumber(value) {
    return !isNaN(this.toInteger(value));
  }
  padNumber(value) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
  format(date: NgbDateStruct | null): string {
    if (!date) return '';
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return this.localizedDatePipe.transform(jsDate);
  }
}
