<div class="bg-white" [class.full-height]="fullPage()">
  @if (loading) {
    <div class="row py-4 justify-content-center">
      <div class="col-auto">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </div>
  }
  @if (page === 'gdpr' && !loading && logged && !hu.RGPDValidated) {
    <div class="container-fluid mb-4">
      <div class="row pt-3">
        <div class="col">
          <h2 class="text-center">{{ 'rgpd.Merci de prendre connaissance des conditions RGPD et de cliquer en bas de page' | translate }}</h2>
        </div>
      </div>
    </div>
  }
  <div [innerHTML]="html"></div>
  @if (page === 'gdpr' && !loading && logged) {
    <div class="container-fluid">
      @if (!hu.RGPDValidated) {
        <div class="row bg-white py-4 justify-content-center">
          <div class="col-auto">
            <button class="btn btn-success" type="button" (click)="accept()">
              {{ "rgpd.J'ai bien pris connaissance de la politique de confidentialité" | translate }}
              @if (loadingAction) {
                <i class="fa-light fa-spinner spinning ms-2"></i>
              }
            </button>
          </div>
        </div>
      }
      @if (hu.RGPDValidated) {
        <div class="row bg-white py-4 justify-content-center">
          <div class="col-auto">
            <div class="alert alert-info">
              {{ 'rgpd.Vous avez bien pris connaissance de notre politique de confidentialité le' | translate }}
              {{ hu.RGPDValidationdate | localizedDate }}
            </div>
          </div>
        </div>
      }
    </div>
  }
  @if (page === 'discover' && !loading) {
    <div class="container-fluid">
      <div class="row bg-white py-4 justify-content-center">
        <div class="col-auto">
          <button class="btn btn-primary" (click)="startAdventure()">{{ "discover.Démarrer l'aventure !" | translate }}</button>
        </div>
      </div>
    </div>
  }
  @if (page === 'maj' && !loading) {
    <div class="container-fluid">
      <div class="row bg-white py-4 justify-content-center">
        <div class="col-auto">
          <button class="btn btn-primary" (click)="updateMessageSeen()">
            {{ 'maj.Ok !' | translate }}
            @if (loadingAction) {
              <i class="fa-light fa-spinner spinning ms-2"></i>
            }
          </button>
        </div>
      </div>
    </div>
  }
</div>
