<!-- On mets ici tout les textes à traduire qui sont dynamiques dans le reste du code (cad généré avec des variables) -->
{{ 'menu.Accueil' | translate }}
{{ 'menu.Activités' | translate }}
{{ 'menu.Bienvenue' | translate }}
{{ 'menu.Campagne' | translate }}
{{ 'menu.Campagne 360' | translate }}
{{ 'menu.Campagnes' | translate }}
{{ 'menu.Compétences' | translate }}
{{ 'menu.Configuration' | translate }}
{{ 'menu.Critères' | translate }}
{{ 'menu.Enseigne' | translate }}
{{ 'menu.Feedback' | translate }}
{{ 'menu.Feedbacks' | translate }}
{{ 'menu.Feedbacks categories' | translate }}
{{ 'menu.Fonction' | translate }}
{{ 'menu.Gestes observables' | translate }}
{{ 'menu.Modules' | translate }}
{{ 'menu.Pages Statiques' | translate }}
{{ 'menu.Référentiels' | translate }}
{{ 'menu.Région' | translate }}
{{ 'menu.Réseau' | translate }}
{{ 'menu.Société' | translate }}
{{ 'menu.Support' | translate }}
{{ 'menu.Utilisateurs' | translate }}
{{ 'menu.Établissement' | translate }}
{{ 'menu.Population' | translate }}
{{ 'menu.Restitution' | translate }}
{{ 'menu.Rapports' | translate }}
{{ 'menu.Email génériques' | translate }}
{{ 'menu.Avis' | translate }}
{{ 'menu.Enquêtes' | translate }}
{{ 'menu.Questionnaires' | translate }}
{{ 'menu.Questions' | translate }}
{{ 'menu.Catégories' | translate }}
{{ 'menu.Configuration rapports 180' | translate }}
{{ 'menu.Configuration rapports EMA' | translate }}
{{ 'menu.Configuration Périmètre' | translate }}
{{ 'menu.Certification' | translate }}
{{ 'menu.Modalités' | translate }}
{{ 'menu.Configuration Front' | translate }}
{{ 'menu.Configuration Back' | translate }}
{{ 'menu.Configuration Manifest' | translate }}
{{ 'menu.Quiz' | translate }}
{{ 'menu.Formation' | translate }}
{{ 'menu.Impact Formation' | translate }}
{{ 'menu.Roleplay / Interview' | translate }}
{{ 'menu.Customisation CSS' | translate }}
{{ 'menu.Statistiques' | translate }}
{{ 'menu.Traduction' | translate }}
{{ 'menu.Verbatims' | translate }}
{{ 'menu.Verbatim categories' | translate }}
{{ 'menu.Évenements' | translate }}
{{ 'menu.Statistiques Évenements' | translate }}
{{ 'menu.Fichiers KPI' | translate }}
{{ 'menu.Listes de diffusion' | translate }}
{{ 'menu.Parcours de formation' | translate }}
{{ 'menu.Actions de coaching' | translate }}

{{ 'global.title.add' | translate }}
{{ 'global.title.edit' | translate }}
{{ 'global.title.view' | translate }}

{{ 'campaign-stat.skill-radar.Moyenne auto-observation' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne premières auto-observation' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne dernières auto-observation' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne manager' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne premières observations manager' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne dernières observations manager' | translate }}

{{ 'mif.formation-stats.skill-radar.Moyenne acquisition avant' | translate }}
{{ 'mif.formation-stats.skill-radar.Moyenne acquisition après' | translate }}
{{ 'mif.formation-stats.skill-radar.Moyenne satisfaction participant' | translate }}
{{ 'mif.formation-stats.skill-radar.acquisition avant' | translate }}
{{ 'mif.formation-stats.skill-radar.acquisition après' | translate }}
{{ 'mif.formation-stats.skill-radar.satisfaction participant' | translate }}

{{ 'campaign-stat.skill-radar.Moyenne pair' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne collaborateur' | translate }}
{{ 'campaign-stat.skill-radar.Moyenne externe' | translate }}

{{ 'campaign-stat.observable-gesture-table.Moyenne auto-observation' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne manager' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne pair' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne collaborateur' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne externe' | translate }}

{{ 'campaign-stat.observable-gesture-table.Progression auto-observation' | translate }}
{{ 'campaign-stat.observable-gesture-table.Progression manager' | translate }}
{{ 'campaign-stat.observable-gesture-table.Progression pair' | translate }}
{{ 'campaign-stat.observable-gesture-table.Progression collaborateur' | translate }}
{{ 'campaign-stat.observable-gesture-table.Progression externe' | translate }}

{{ 'campaign-stat.observable-gesture-table.Moyenne première moitiée auto-observation' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne seconde moitiée auto-observation' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne première moitiée manager' | translate }}
{{ 'campaign-stat.observable-gesture-table.Moyenne seconde moitiée manager' | translate }}

{{ 'mif.formation-stats.table.Moyenne acquisition avant' | translate }}
{{ 'mif.formation-stats.table.Moyenne acquisition après' | translate }}
{{ "mif.formation-stats.table.Nombre d'observations acquisition avant" | translate }}
{{ "mif.formation-stats.table.Nombre d'observations acquisition après" | translate }}
{{ 'mif.formation-stats.table.Moyenne satisfaction participant' | translate }}
{{ "mif.formation-stats.table.Nombre d'observations satisfaction participant" | translate }}

{{ 'email.list.EmailModuleMapping.360' | translate }}
{{ 'email.list.EmailModuleMapping.Restitution' | translate }}
{{ 'email.list.EmailModuleMapping.Feedback spontané' | translate }}
{{ 'email.list.EmailModuleMapping.Global' | translate }}
{{ 'email.list.EmailModuleMapping.180' | translate }}
{{ 'email.list.EmailModuleMapping.Enquête' | translate }}
{{ 'email.list.EmailModuleMapping.PDI' | translate }}
{{ 'email.list.EmailModuleMapping.Impact formation' | translate }}
{{ 'email.list.EmailModuleMapping.EMA' | translate }}
